import PropTypes from "prop-types";

import { Ad } from "@components/ad";

export function Sponsorship({ path, type, name, ...adProps }) {
  return (
    <div className="flex items-center justify-center pb-4">
      <p className="text-sm text-center text-black-300 mr-6">{type}</p>
      <Ad path={path} className="" native white placement="logo" {...adProps}>
        <span className="text-sm font-semibold -ml-4">{name}</span>
      </Ad>
    </div>
  );
}

Sponsorship.propTypes = {
  path: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
