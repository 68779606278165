import PropTypes from "prop-types";

/**
 *
 *  to add more copy utilies for non-text fields:
 *  https://web.dev/async-clipboard/
 *
 */

export async function copyTextToClipboard(text, onSuccess, onError) {
  if ("clipboard" in navigator) {
    try {
      await navigator.clipboard.writeText(text);
      onSuccess();
    } catch (err) {
      onError();
    }
  } else {
    try {
      document.execCommand("copy", true, text);
      onSuccess();
    } catch (err) {
      onError();
    }
  }
}

copyTextToClipboard.propTypes = {
  text: PropTypes.string,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
};

copyTextToClipboard.defaultProps = {
  text: "",
  onError: () => {},
  onSuccess: () => {},
};
