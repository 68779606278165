/**
 * TODO: Awaiting design direction for cases with multiple authors.
 * https://redventures.slack.com/archives/C02QM2RUJNR/p1646409118250609?thread_ts=1646336246.838539&cid=C02QM2RUJNR
 */
import PropTypes from "prop-types";
import cn from "classnames";

import { ConditionalWrapper } from "@utils/conditionalWrapper";
import { keyify } from "@utils/keyify";

import { Headshot } from "../headshot";

const classMap = {
  0: "z-40",
  1: "z-30",
  2: "z-20",
  3: "z-10",
  4: "z-0",
};

const widthMap = {
  1: "min-w-[64px]",
  2: "min-w-[104px]",
  3: "min-w-[144px]",
  4: "min-w-[184px]",
  5: "min-w-[224px]",
};

const translateMap = {
  0: "translate-x-0",
  1: "translate-x-10",
  2: "translate-x-20",
  3: "translate-x-[7.5rem]",
  4: "translate-x-40",
};

export function Meta({ className = "", authors, date, readtime }) {
  return (
    <div className={cn("flex items-center justify-center", className)}>
      <div className="flex-none">
        {/* Hide headshots if more than 5 authors */}
        {authors.length <= 5 && (
          <div
            className={cn("relative h-16", widthMap[authors.length], {
              // Hide on mobile if there are more than 3 authors
              "hidden md:block": authors.length >= 3,
            })}
          >
            {authors.map((author, i) => (
              <div
                key={keyify(author.name)}
                className={cn(
                  "absolute top-0 left-0",
                  translateMap[i],
                  classMap[i]
                )}
              >
                <Headshot {...author} />
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="ml-4 min-w-[180px]">
        {authors.map(({ name, url }, i) => (
          <ConditionalWrapper
            key={`${name}-wrapper`}
            condition={url}
            // eslint-disable-next-line react/no-unstable-nested-components
            wrapper={children => (
              <a
                href={url}
                rel="author"
                className="no-underline text-blue text-md"
              >
                {children}
              </a>
            )}
          >
            <span
              key={name}
              className={cn("font-semibold", {
                "after:content-[','] mr-2":
                  authors.length > 1 && i + 1 !== authors.length,
              })}
            >
              {name}
            </span>
          </ConditionalWrapper>
        ))}

        <p className="text-sm">
          {date} &bull; {readtime} min read
        </p>
      </div>
    </div>
  );
}

Meta.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ).isRequired,
  date: PropTypes.string.isRequired,
  readtime: PropTypes.number.isRequired,
  className: PropTypes.string,
};
