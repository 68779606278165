import { useCallback } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { tagular } from "@cohesion/tagular";

const appendSlashPrefix = slug => `${slug[0] === "/" ? slug : `/${slug}`}`;

export function Crumb({ slug = "", blue = false, title }) {
  const handleCrumbClick = useCallback(() => {
    tagular("click", {
      actionOutcome: "INTERNALLINK",
      outboundUrl: "NULL",
      webElement: {
        location: "SECTION",
        elementType: "LINK",
        position: "OVERVIEW",
        text: "BREADCRUMBS",
      },
    });
  }, []);

  if (!slug) return title;

  return (
    <a
      href={appendSlashPrefix(slug)}
      className={cn(
        "transition-colors ease-out cursor-pointer inline-block",
        blue
          ? "text-blue no-underline"
          : "text-black hover:text-blue link-underline"
      )}
      itemProp="item"
      id={slug}
      onClick={handleCrumbClick}
    >
      <span itemProp="name">{title}</span>
    </a>
  );
}

Crumb.propTypes = {
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  blue: PropTypes.bool,
};
