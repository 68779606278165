import PropTypes from "prop-types";
import { ProfileFill } from "@icons";
import { ImgixNextImage } from "@components/imgixNextImage";

export function Headshot({ image = "", name }) {
  if (!image)
    return (
      <ProfileFill width="64" height="64" className="text-blue rounded-full" />
    );

  return (
    <ImgixNextImage
      src={image}
      alt={name}
      width={64}
      height={64}
      imgixParams={{ w: 64, h: 64, fit: "crop", crop: "faces" }}
      className="rounded-full border-2 border-sand-300"
    />
  );
}

Headshot.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
};
