export const tableCopy = [
  {
    heading: "Supported by",
    body: "Scheduled stories that feature our client’s name because they align to our concept.",
    roles: [
      {
        heading: "Brand partner",
        body: "Only provides financial support and their logo. Doesn't make revisions or approve the story.",
      },
      {
        heading: "Lonely Planet",
        body: "We fully control the creative and editorial approach, just like any other featured story.",
      },
    ],
  },
  {
    heading: "Sponsored by",
    body: "Stories where we collaborate and welcome direct input from our client.",
    roles: [
      {
        heading: "Brand partner",
        body: "Determines the concept, provides briefing, research material, and may provide feedback.",
      },
      {
        heading: "Lonely Planet",
        body: "We provide expertise, firsthand insights, and verify with third-party sources when needed.",
      },
    ],
  },
  {
    heading: "Paid for by",
    body: "Inspiring stories that highlight aspects that are important for our client",
    roles: [
      {
        heading: "Brand partner",
        body: "Commissions the work to our team, provides full direction and makes revisions.",
      },
      {
        heading: "Lonely Planet",
        body: "We craft ideas and create messaging that resonates with our audience.",
      },
    ],
  },
  {
    heading: "Provided by",
    body: "Stories written by our clients that we believe are worth reading.",
    roles: [
      {
        heading: "Brand partner",
        body: "Provides content that was produced without cooperation from Lonely Planet",
      },
      {
        heading: "Lonely Planet",
        body: "We review compliance with advertising authorities and publish on our platforms",
      },
    ],
  },
];
