import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { Breadcrumbs } from "@components/breadcrumbs";
import { SectionHeading } from "@components/headings/section";
import { ImgixNextImage } from "@components/imgixNextImage";
import { SaveButton } from "@components/saveButton";
import { Share } from "@components/share";
import { Sponsorship } from "@pageComponents/articles/sponsorship";

import { Meta } from "./meta";

export function Header({
  breadcrumbs,
  title,
  authors,
  readtime,
  pubtime,
  featuredImage,
  esid,
  slug,
  excerpt,
  sponsorship,
}) {
  const captionRef = useRef(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (captionRef.current) {
      setOffset(captionRef.current.offsetHeight);
    }
  }, [captionRef]);

  return (
    <header id="header" className="relative z-10">
      {/**
       * Calculating this way ensures that we account for variance in the caption height, and always end up N pixels shorter than the
       * bottom of the image without having to do much math to get there, e.g. calculating a percent of header / image-height, etc.
       */}
      <div
        className="absolute top-0 w-full bg-sand-200"
        style={{
          height: featuredImage?.url
            ? `calc(100% - ${offset}px - 100px)`
            : "100%",
        }}
      />
      <div
        className={cn("relative z-1 pt-8", {
          "pb-4": !featuredImage?.url,
        })}
      >
        <div className="container mb-10">
          <div className="lg:flex items-center mb-10">
            <Breadcrumbs breadcrumbs={breadcrumbs} blue useCarat />
            <div className="hidden lg:flex items-center">
              <div className="max-w-24 mr-10">
                <SaveButton esid={esid} type="article" slug={slug} />
              </div>
              <Share
                content={{
                  url: slug,
                  image: featuredImage?.url,
                  title,
                  excerpt,
                }}
                horizontal
              />
            </div>
          </div>

          <SectionHeading
            elem="h1"
            className="max-w-5xl mx-auto text-center my-24"
            fontNormal
          >
            {title}
          </SectionHeading>
          {sponsorship && (
            <div className="relative mt-12 lg:-mt-12 z-20">
              <div className="mb-6 lg:mb-24">
                <Sponsorship
                  bgColor="bg-transparent"
                  path="articles/header-badge-logo"
                  {...sponsorship}
                />
              </div>
            </div>
          )}
          
          <div className="text-center sm:flex sm:flex-wrap sm:items-center sm:justify-center lg:hidden">
            <div className="flex justify-center mb-6 sm:mr-10 sm:mb-0">
              <SaveButton esid={esid} type="article" slug={slug} />
            </div>
            <Share
              content={{
                url: slug,
                image: featuredImage?.url,
                title,
                excerpt,
              }}
              horizontal
            />
          </div>
        </div>

        <div className="lg:container lg:px-0">
          {featuredImage?.url && (
            <>
              <ImgixNextImage
                priority
                src={featuredImage?.url}
                className="w-full"
                alt={featuredImage?.alt}
                width={1440}
                height={810}
                imgixParams={{ w: 1440, h: 810, fit: "crop" }}
              />

              {featuredImage?.caption && (
                <div className="container xl:max-w-6xl xl:border-sand-300 xl:border xl:border-b-0 xl:border-t-0">
                  <p
                    className="p-2 text-xs text-right container lg:max-w-4xl"
                    ref={captionRef}
                  >
                    {featuredImage?.caption}
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </header>
  );
}
const { arrayOf, number, shape, string } = PropTypes;

Header.propTypes = {
  breadcrumbs: arrayOf(
    shape({
      slug: string,
      title: string,
    })
  ).isRequired,
  title: string.isRequired,
  slug: PropTypes.string.isRequired,
  esid: PropTypes.string.isRequired,
  authors: arrayOf(
    shape({
      image: string,
      name: string.isRequired,
      type: string,
      url: string,
    })
  ).isRequired,
  readtime: number.isRequired,
  pubtime: string.isRequired,
  featuredImage: shape({
    alt: string,
    caption: string,
    credit: string,
    height: number,
    name: string,
    title: string,
    url: string,
    width: number,
  }).isRequired,
  excerpt: string.isRequired,
  sponsorship: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
  }),
};
