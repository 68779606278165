export const elsewhereDestinations = [
  {
    name: "Argentina",
    lpSlugs: ["argentina"],
    destinationUrl: "https://www.elsewhere.io/argentina",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=argentina`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/argentina",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/ARGENTINA_Stocksy_txp6bab0d6dWui300_Medium_160354.jpg",
  },
  {
    name: "Armenia",
    lpSlugs: ["armenia", "georgia-armenia-azerbaijan"],
    destinationUrl: "https://www.elsewhere.io/armenia",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=armenia`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/armenia",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/ARMENIA_shutterstock_2075204599.jpg",
    driver: false,
  },
  {
    name: "Benin",
    lpSlugs: ["benin"],
    destinationUrl: "https://www.elsewhere.io/benin",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=benin`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/benin",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/BENIN-TOGO-Stocksytxp2217b51fSur300Medium3972023.jpg",
    driver: false,
  },
  {
    name: "Bolivia",
    lpSlugs: ["bolivia"],
    destinationUrl: "https://www.elsewhere.io/bolivia",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=bolivia`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/bolivia",
    imageUrl: null,
    driver: false,
  },
  {
    name: "Botswana",
    lpSlugs: ["botswana", "botswana-namibia"],
    destinationUrl: "https://www.elsewhere.io/botswana",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=botswana`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/botswana",
    imageUrl: null,
    driver: false,
  },
  {
    name: "Brazil",
    lpSlugs: ["brazil"],
    destinationUrl: "https://www.elsewhere.io/brazil",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=brazil`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/brazil",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/BRAZIL_GettyImages-1133184993.jpg",
  },
  {
    name: "Canada",
    lpSlugs: ["canada"],
    destinationUrl: "https://www.elsewhere.io/canada",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=canada`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/canada",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/CANADA-Stocksytxp2217b51fSur300Medium823536.jpg",
  },
  {
    name: "Chile",
    lpSlugs: ["chile"],
    destinationUrl: "https://www.elsewhere.io/chile",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=chile`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/chile",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/CHILE_GettyImages-1439791249.jpg",
  },
  {
    name: "Colombia",
    lpSlugs: ["colombia"],
    destinationUrl: "https://www.elsewhere.io/colombia",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=colombia`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/colombia",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/COLOMBIA_Colombia%20167_PNN_COL_Mazars-Lonely_Planet.jpg",
  },
  {
    name: "Corsica",
    lpSlugs: ["france/corsica"],
    destinationUrl: "https://www.elsewhere.io/corsica",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=corsica`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/corsica",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/GettyImages-1449877100-RFC.jpg",
  },
  {
    name: "Costa Rica",
    lpSlugs: ["costa-rica"],
    destinationUrl: "https://www.elsewhere.io/costa-rica",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=costa-rica`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/costa-rica",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/COSTA-RICA_Stocksy_txp6bab0d6dWui300_Medium_1882695.jpg",
  },
  {
    name: "Croatia",
    lpSlugs: ["croatia"],
    destinationUrl: "https://www.elsewhere.io/croatia",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=croatia`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/croatia",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/CROATIA_GettyImages-1064158618.jpeg",
  },
  {
    name: "Dominican Republic",
    lpSlugs: ["dominican-republic"],
    destinationUrl: "https://www.elsewhere.io/dominican-republic",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=dominican-republic`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/dominican-republic",
    imageUrl: null,
    driver: false,
  },
  {
    name: "Easter Island",
    lpSlugs: ["chile/rapa-nui-easter-island"],
    destinationUrl: "https://www.elsewhere.io/easter-island",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=easter-island`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/easter-island",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/EASTER-ISLAND-GettyRF469768771.jpg",
  },
  {
    name: "Ecuador",
    lpSlugs: ["ecuador"],
    destinationUrl: "https://www.elsewhere.io/ecuador",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=ecuador`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/ecuador",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/GALAPAGOS_Stocksy_txp6bab0d6dWui300_Medium_3056563.jpg",
    driver: false,
  },
  {
    name: "Egypt",
    lpSlugs: ["egypt"],
    destinationUrl: "https://www.elsewhere.io/egypt",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=egypt`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/egypt",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/EGYPT_GettyImages-1307368749.jpg",
    driver: false,
  },
  {
    name: "England",
    lpSlugs: ["england"],
    destinationUrl: "https://www.elsewhere.io/england",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=england`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/england",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/ENGLAND-Stocksytxp2217b51fSur300Medium1512143.jpg",
  },
  {
    name: "Estonia",
    lpSlugs: ["estonia"],
    destinationUrl: "https://www.elsewhere.io/estonia",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=estonia`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/estonia",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/BATLIC-Stocksy_txpdafd8a208pl300_Medium_1586294.jpg",
    driver: false,
  },
  {
    name: "Finland",
    lpSlugs: ["finland"],
    destinationUrl: "https://www.elsewhere.io/finland",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=finland`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/finland",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/FINLAND_Stocksy_txp14a02f81yiS300_Medium_2079412.jpg",
  },
  {
    name: "France",
    lpSlugs: ["france"],
    destinationUrl: "https://www.elsewhere.io/france",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=france`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/france",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/FRANCE_Stocksy_txp95a12c14B4D300_Medium_1091988.jpg",
  },
  {
    name: "French Guiana",
    lpSlugs: ["the-guianas/french-guiana"],
    destinationUrl: "https://www.elsewhere.io/french-guiana",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=french-guiana`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/french-guiana",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/FRENCH-GUIANA-Stocksytxpaa763088k0s300Medium5359764-RFC.jpg",
    driver: false,
  },
  {
    name: "Ghana",
    lpSlugs: ["ghana"],
    destinationUrl: "https://www.elsewhere.io/ghana",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=ghana`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/ghana",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/GHANA_ransford-quaye-pq66fmckyGE-unsplash.jpg",
    driver: false,
  },
  {
    name: "Greece",
    lpSlugs: ["greece"],
    destinationUrl: "https://www.elsewhere.io/greece",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=greece`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/greece",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/GREECE_Stocksy_txpd3e207deiBV300_Medium_35629.jpg",
  },
  {
    name: "Guyana",
    lpSlugs: ["the-guianas/guyana"],
    destinationUrl: "https://www.elsewhere.io/guyana",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=guyana`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/guyana",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/GUYANA_shutterstock_747421726_0.jpg",
  },
  {
    name: "Iceland",
    lpSlugs: ["iceland"],
    destinationUrl: "https://www.elsewhere.io/iceland",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=iceland`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/iceland",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/ICELAND_Stocksy_txp41b27a5fq7S300_Medium_3384614.jpg",
  },
  {
    name: "India",
    lpSlugs: ["india"],
    destinationUrl: "https://www.elsewhere.io/india",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=india`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/india",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/INDIA-GETTY-IMAGES.jpg",
  },
  {
    name: "Ireland",
    lpSlugs: ["ireland"],
    destinationUrl: "https://www.elsewhere.io/ireland",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=ireland`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/ireland",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/IRELAND-Stocksytxp95a12c14B4D300Medium3464926.jpg",
  },
  {
    name: "Italy",
    lpSlugs: ["italy"],
    destinationUrl: "https://www.elsewhere.io/italy",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=italy`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/italy",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/ITALY_Stocksy_txp6bab0d6dWui300_Medium_417466.jpg",
  },
  {
    name: "Japan",
    lpSlugs: ["japan"],
    destinationUrl: "https://www.elsewhere.io/japan",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=japan`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/japan",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/JAPAN_GettyImages-1227451731.jpg",
  },
  {
    name: "Jordan",
    lpSlugs: ["jordan"],
    destinationUrl: "https://www.elsewhere.io/jordan",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=jordan`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/jordan",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/Stocksy_txp169bcf2dr9m300_Medium_5402806.jpg",
    driver: false,
  },
  {
    name: "Lapland",
    lpSlugs: ["finland/lapland"],
    destinationUrl: "https://www.elsewhere.io/lapland",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=lapland`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/lapland",
    imageUrl: null,
  },
  {
    name: "Latvia",
    lpSlugs: ["latvia"],
    destinationUrl: "https://www.elsewhere.io/latvia",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=latvia`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/latvia",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/BATLIC-Stocksy_txpdafd8a208pl300_Medium_1586294.jpg",
    driver: false,
  },
  {
    name: "Lithuania",
    lpSlugs: ["lithuania"],
    destinationUrl: "https://www.elsewhere.io/lithuania",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=lithuania`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/lithuania",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/BATLIC-Stocksy_txpdafd8a208pl300_Medium_1586294.jpg",
    driver: false,
  },
  {
    name: "Maldives",
    lpSlugs: ["maldives"],
    destinationUrl: "https://www.elsewhere.io/maldives",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=maldives`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/maldives",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-02/GettyImages-1406472970-RFC.jpg",
    driver: false,
  },
  {
    name: "Mallorca",
    lpSlugs: ["spain/mallorca"],
    destinationUrl: "https://www.elsewhere.io/mallorca",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=mallorca`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/mallorca",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/MALLORCA-GettyImages-1439267919-RFC.jpg",
  },
  {
    name: "Mexico",
    lpSlugs: ["mexico"],
    destinationUrl: "https://www.elsewhere.io/mexico",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=mexico`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/mexico",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/MEXICO_Stocksy_txp95a12c14B4D300_Medium_2570571.jpg",
  },
  {
    name: "Mongolia",
    lpSlugs: ["mongolia"],
    destinationUrl: "https://www.elsewhere.io/mongolia",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=mongolia`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/mongolia",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/MONGOLIA_GettyImages-1221850805.jpg",
  },
  {
    name: "Morocco",
    lpSlugs: ["morocco"],
    destinationUrl: "https://www.elsewhere.io/morocco",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=morocco`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/morocco",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/MOROCCO_Stocksy_txp6bab0d6dWui300_Medium_4796393.jpg",
  },
  {
    name: "Mozambique",
    lpSlugs: ["mozambique"],
    destinationUrl: "https://www.elsewhere.io/mozambique",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=mozambique`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/mozambique",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/MOZAMBIQUE-GettyImages-1173557214-RFC.jpg",
    driver: false,
  },
  {
    name: "Nepal",
    lpSlugs: ["nepal"],
    destinationUrl: "https://www.elsewhere.io/nepal",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=nepal`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/nepal",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/NEPAL_Stocksy_txp6bab0d6dWui300_Medium_2148247.jpg",
    driver: false,
  },
  {
    name: "New Zealand",
    lpSlugs: ["new-zealand"],
    destinationUrl: "https://www.elsewhere.io/new-zealand",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=new-zealand`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/new-zealand",
    imageUrl:
      "https://lonelyplanetstatic.imgix.net/marketing/2024/elsewhere/New_Zealand-GettyImages-1138486505.jpg",
  },
  {
    name: "Panama",
    lpSlugs: ["panama"],
    destinationUrl: "https://www.elsewhere.io/panama",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=panama`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/panama",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/PANAMA-Stocksytxp2217b51fSur300Medium3665673.jpg",
    driver: false,
  },
  {
    name: "Pantanal",
    lpSlugs: ["brazil/the-central-west/the-pantanal"],
    destinationUrl: "https://www.elsewhere.io/pantanal",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=pantanal`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/pantanal",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/PANTANAL-Stocksytxp2217b51fSur300Medium5421920.jpg",
  },
  {
    name: "Patagonia",
    lpSlugs: [
      "argentina/patagonia",
      "argentina/inland-patagonia",
      "chile/northern-patagonia",
      "chile/southern-patagonia",
    ],
    destinationUrl: "https://www.elsewhere.io/patagonia",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=patagonia`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/patagonia",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/PATAGONIA_shutterstockRF_1397527424.jpg",
  },
  {
    name: "Peru",
    lpSlugs: ["peru"],
    destinationUrl: "https://www.elsewhere.io/peru",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=peru`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/peru",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/PERU_Stocksy_txp6bab0d6dWui300_Medium_4773064.jpg",
  },
  {
    name: "Portugal",
    lpSlugs: ["portugal"],
    destinationUrl: "https://www.elsewhere.io/portugal",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=portugal`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/portugal",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/PORTUGAL_Stocksy_txp6bab0d6dWui300_Medium_4212738.jpg",
  },
  {
    name: "Rwanda",
    lpSlugs: ["rwanda"],
    destinationUrl: "https://www.elsewhere.io/rwanda",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=rwanda`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/rwanda",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/RWANDA-GettyImages-164426452-RFC.jpg",
    driver: false,
  },
  {
    name: "Sardinia",
    lpSlugs: ["italy/sardinia"],
    destinationUrl: "https://www.elsewhere.io/sardinia",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=sardinia`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/sardinia",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/SARDINIA-GettyImages-1202315585-RFC.jpg",
  },
  {
    name: "São Tomé and Príncipe",
    lpSlugs: ["sao-tome-and-principe"],
    destinationUrl: "https://www.elsewhere.io/sao-tome-and-principe",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=sao-tome-and-principe`,
    requestUrl:
      "https://www.elsewhere.io/request-to-travel/sao-tome-and-principe",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/SAO-TOME-shutterstock2371092419.jpg",
  },
  {
    name: "Scotland",
    lpSlugs: ["scotland"],
    destinationUrl: "https://www.elsewhere.io/scotland",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=scotland`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/scotland",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/SCOTLAND_Stocksy_txp57bdade2KHV300_Small_3098191.jpg",
  },
  {
    name: "Senegal",
    lpSlugs: ["senegal"],
    destinationUrl: "https://www.elsewhere.io/senegal",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=senegal`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/senegal",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/SENEGAL-shutterstock_2252760287_1.jpg",
    driver: false,
  },
  {
    name: "Sicily",
    lpSlugs: ["italy/sicily"],
    destinationUrl: "https://www.elsewhere.io/sicily",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=sicily`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/sicily",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/ITALY_Stocksy_txp6bab0d6dWui300_Medium_417466.jpg",
  },
  {
    name: "Sierra Leone",
    lpSlugs: ["sierra-leone"],
    destinationUrl: "https://www.elsewhere.io/sierra-leone",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=sierra-leone`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/sierra-leone",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/SIERRA-LEONE-Stocksytxp2217b51fSur300Medium5289134.jpg",
    driver: false,
  },
  {
    name: "South Africa",
    lpSlugs: ["south-africa"],
    destinationUrl: "https://www.elsewhere.io/south-africa",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=south-africa`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/south-africa",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/SOUTH-AFRICA_Stocksy_txp6bab0d6dWui300_Medium_1941061.jpg",
  },
  {
    name: "South Korea",
    lpSlugs: ["south-korea"],
    destinationUrl: "https://www.elsewhere.io/south-korea",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=south-korea`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/south-korea",
    imageUrl:
      "https://lonelyplanetstatic.imgix.net/marketing/2024/elsewhere/SOUTH_KOREA-shutterstockRF_1135923056.jpg",
  },
  {
    name: "Spain",
    lpSlugs: ["spain"],
    destinationUrl: "https://www.elsewhere.io/spain",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=spain`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/spain",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/SPAIN_Stocksy_txp5523397eYui300_Medium_754880.jpg",
  },
  {
    name: "Sri Lanka",
    lpSlugs: ["sri-lanka"],
    destinationUrl: "https://www.elsewhere.io/sri-lanka",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=sri-lanka`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/sri-lanka",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/SRI-LANKA_GettyImages-1266076291.jpg",
  },
  {
    name: "Suriname",
    lpSlugs: ["the-guianas/suriname"],
    destinationUrl: "https://www.elsewhere.io/suriname",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=suriname`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/suriname",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/SURINAME-Stocksytxpaa763088k0s300Medium4674983-RFC.jpg",
  },
  {
    name: "Switzerland",
    lpSlugs: ["switzerland"],
    destinationUrl: "https://www.elsewhere.io/switzerland",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=switzerland`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/switzerland",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/SWITZERLAND_GettyImages-1146488670.jpg",
  },
  {
    name: "Tanzania",
    lpSlugs: ["tanzania"],
    destinationUrl: "https://www.elsewhere.io/tanzania",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=tanzania`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/tanzania",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/TANZANIA_GettyImages-472058123.jpg",
  },
  {
    name: "Thailand",
    lpSlugs: ["thailand"],
    destinationUrl: "https://www.elsewhere.io/thailand",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=thailand`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/thailand",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/THAILAND_Stocksy_txp6bab0d6dWui300_Medium_2434175.jpg",
  },
  {
    name: "The Azores",
    lpSlugs: ["portugal/the-azores-1341351"],
    destinationUrl: "https://www.elsewhere.io/the-azores",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=the-azores`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/the-azores",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/AZORES_GettyImages-665417736.jpg",
  },
  {
    name: "The Baltic States",
    lpSlugs: ["the-baltic-states"],
    destinationUrl: "https://www.elsewhere.io/the-baltic-states",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=the-baltic-states`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/the-baltic-states",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/BATLIC-Stocksy_txpdafd8a208pl300_Medium_1586294.jpg",
  },
  {
    name: "The Galapagos Islands",
    lpSlugs: ["ecuador/the-galapagos-islands"],
    destinationUrl: "https://www.elsewhere.io/the-galapagos",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=the-galapagos`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/the-galapagos",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/GALAPAGOS_Stocksy_txp6bab0d6dWui300_Medium_3056563.jpg",
  },
  {
    name: "Slovenia",
    lpSlugs: ["slovenia"],
    destinationUrl: "https://www.elsewhere.io/slovenia",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=slovenia`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/slovenia",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/SLOVENIA-Stocksy_txp21052ae2nSG300_Small_1694493.jpg",
  },
  {
    name: "Togo",
    lpSlugs: ["togo"],
    destinationUrl: "https://www.elsewhere.io/togo",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=togo`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/togo",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/BENIN-TOGO-Stocksytxp2217b51fSur300Medium3972023.jpg",
    driver: false,
  },
  {
    name: "Turkey",
    lpSlugs: ["turkey"],
    destinationUrl: "https://www.elsewhere.io/turkey",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=turkey`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/turkey",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/TURKEY_Stocksy_txp6bab0d6dWui300_Medium_1955701.jpg",
  },
  {
    name: "Uganda",
    lpSlugs: ["uganda"],
    destinationUrl: "https://www.elsewhere.io/uganda",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=uganda`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/uganda",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/UGANDA-clinton-mwebaze-uw5aA3zalt4-RFC.jpg",
    driver: false,
  },
  {
    name: "Uruguay",
    lpSlugs: ["uruguay"],
    destinationUrl: "https://www.elsewhere.io/uruguay",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=uruguay`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/uruguay",
    imageUrl: null,
    driver: false,
  },
  {
    name: "Vietnam",
    lpSlugs: ["vietnam"],
    destinationUrl: "https://www.elsewhere.io/vietnam",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=vietnam`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/vietnam",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2023-08/VIETNAM_GettyImages-1382966304.jpg",
  },
  {
    name: "Wales",
    lpSlugs: ["wales"],
    destinationUrl: "https://www.elsewhere.io/wales",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=wales`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/wales",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/WALES-Stocksytxpaa763088k0s300Medium1271949-RFC.jpg",
    driver: false,
  },
  {
    name: "Zambia",
    lpSlugs: ["zambia"],
    destinationUrl: "https://www.elsewhere.io/zambia",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=zambia`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/zambia",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/ZAMBIA-shutterstock313390508-RFC.jpg",
    driver: false,
  },
  {
    name: "Zimbabwe",
    lpSlugs: ["zimbabwe"],
    destinationUrl: "https://www.elsewhere.io/zimbabwe",
    embedUrl: `${process.env.NEXT_PUBLIC_ELSEWHERE_REQUEST_FORM}/?destination=zimbabwe`,
    requestUrl: "https://www.elsewhere.io/request-to-travel/zimbabwe",
    imageUrl:
      "https://lp-cms-production.imgix.net/public/2024-01/ZIMBABWE-GettyImages-865534310RFC.jpg",
    driver: false,
  },
];
