import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { airbrakeClient } from "@utils/airbrake/browser";

import { UserContext } from "@context/user";

import { Login } from "@components/login";

import { Bookmark, BookmarkFill } from "@icons";

import { tagular } from "@cohesion/tagular";

export function SaveButton({ esid, type, slug }) {
  const [favorite, setFavorite] = useState(false);

  const { user, mutate } = useContext(UserContext);

  /**
   * Create a new, generic favorite item
   *
   * FYI: If we shift to using gql mutations here, we can leverage
   * `optimisticResponse` in the mutation().
   */
  const create = async () => {
    setFavorite({ type, esid, optimistic: true });

    await fetch(`/api/favorites`, {
      method: "POST",
      body: JSON.stringify({
        ref_type: type,
        ref_id: esid,
        ref_slug: slug,
      }),
    })
      .then(res => res.json())
      .then(() => {
        mutate();
        tagular("click", {
          actionOutcome: "SAVE",
          outboundUrl: `${process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_URL}/${slug}`,
          webElement: {
            location: "SECTION",
            text: "SAVE",
            name: "",
            elementType: "BUTTON",
            position: type,
          },
        });
      })
      .catch(err => {
        /**
         * Something on the network failed, roll back that optimistic favorite
         */
        setFavorite(null);
        console.error(err); // eslint-disable-line no-console
        airbrakeClient.notify({
          error: err,
          context: { component: "saveButton.jsx#create" },
        });
      });
  };

  /**
   * Delete a favorite
   */
  const destroy = async () => {
    const prev = favorite;
    setFavorite(null);

    await fetch(`/api/favorites/${favorite.id}`, {
      method: "DELETE",
    })
      .then(res => res.json())
      .then(() => {
        setFavorite(null); // optimistic
        tagular("click", {
          actionOutcome: "UNSAVE",
          outboundUrl: `${process.env.NEXT_PUBLIC_DOTCOM_FRONTEND_URL}/${slug}`,
          webElement: {
            location: "SECTION",
            text: "SAVE",
            name: "",
            elementType: "BUTTON",
            position: type,
          },
        });
      })
      .catch(err => {
        /**
         * Something went wrong on the network, restore the previous favorite
         */
        setFavorite(prev);
        console.error(err); // eslint-disable-line no-console
        airbrakeClient.notify({
          error: err,
          context: { component: "saveButton.jsx#destroy" },
        });
      });
  };

  useEffect(() => {
    const fav = user?.favorites.find(f => f.esid === esid);
    if (fav) {
      setFavorite(fav);
    }
  }, [user, esid]);

  if (!user) {
    return (
      <Login
        action="bookmark"
        className="flex items-center gap-2 rounded-pill bg-black px-4 py-2 text-white font-semibold"
      >
        Save <Bookmark />
      </Login>
    );
  }

  return (
    <button
      type="button"
      className={cn(
        "flex items-center gap-2 rounded-pill px-4 py-2 text-white font-semibold",
        favorite ? "bg-blue" : "bg-black"
      )}
      onClick={favorite ? destroy : create}
    >
      {favorite ? (
        <>
          Saved <BookmarkFill />
        </>
      ) : (
        <>
          Save <Bookmark />
        </>
      )}
    </button>
  );
}

SaveButton.propTypes = {
  esid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};
