import { FacebookFlat, PinterestFlat, RedditFlat, X } from "@icons/social";
import { EmailFill } from "@icons";

export const engine = {
  networks: {
    facebook: {
      url: ({ url }) =>
        `https://www.facebook.com/dialog/share?app_id=111537044496&display=popup&href=${encodeURIComponent(
          url
        )}`,
      icon: FacebookFlat,
    },
    twitter: {
      url: ({ title, url }) =>
        `https://twitter.com/intent/tweet?text=${title}&url=${url}&via=lonelyplanet`,
      icon: X,
    },
    email: {
      url: ({ title, excerpt, url }) =>
        `mailto:?subject=${title}&body=${excerpt}%0A%0A${url}`,
      icon: EmailFill,
    },
    pinterest: {
      url: ({ image, excerpt, url }) =>
        `https://www.pinterest.com/pin/create/button/?url=${url}&media=${image}&description=${excerpt}`,
      icon: PinterestFlat,
    },
    reddit: {
      url: ({ title, url }) =>
        `https://www.reddit.com/submit/?url=${url}&title=${title}`,
      icon: RedditFlat,
    },
  },
  sizes: {
    default: {
      popUpWidth: 840,
      popUpHeight: 420,
    },
    reddit: {
      popUpWidth: 776,
      popUpHeight: 562,
    },
  },
  calcWindowPosition: (windowDimension, popUpDimension) =>
    Math.round(windowDimension / 2 - popUpDimension / 2),
  getWindowSettings: network => {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const size = engine.sizes[network] || engine.sizes.default;

    const settings = {
      ...size,
      height,
      width,
      popUpLeft: engine.calcWindowPosition(width, size.popUpWidth),
      popUpTop:
        width > size.popUpHeight
          ? engine.calcWindowPosition(width, size.popUpWidth)
          : 0,
    };

    return {
      options: "toolbar=no,menubar=no,location=yes,resizable=no,scrollbars=yes",
      windowSize: `width=${size.popUpWidth},height=${size.popUpHeight},top=${settings.popUpTop},left=${settings.popUpLeft}`,
    };
  },
  getUrl: (network, content) => engine.networks[network].url(content),
};
