import PropTypes from "prop-types";
import cn from "classnames";

import { ChevronDownFill } from "@icons";
import { Crumb } from "./crumb";

export function Breadcrumbs({
  breadcrumbs = [],
  blue = false,
  useCarat = false,
  useComma = false,
  inline = false,
}) {
  if (!breadcrumbs[0]) return null;

  return (
    <nav
      className={cn("mb-4", inline ? "inline-block" : "w-full")}
      aria-label="Breadcrumbs"
    >
      <ol itemScope itemType="http://schema.org/BreadcrumbList">
        {breadcrumbs.map(({ hiddenMobile, slug, title }, index) => (
          <li
            className={cn("font-semibold", {
              inline: !hiddenMobile,
              hidden: hiddenMobile,
              "md:inline": hiddenMobile,
            })}
            key={`${title}_${slug}`}
            data-testid="breadcrumb"
            itemScope
            itemProp="itemListElement"
            itemType="http://schema.org/ListItem"
          >
            <Crumb slug={slug} title={title} blue={blue} />

            

            {useComma && index < breadcrumbs.length - 1 && (
              <span className="pr-2" aria-hidden="true">
                ,
              </span>
            )}

            {!useComma && index < breadcrumbs.length - 1 && (
              <span className="px-2" aria-hidden="true">
                {useCarat ? (
                  <ChevronDownFill
                    className={cn("inline -rotate-90 text-tiny")}
                  />
                ) : (
                  "/"
                )}
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
      hiddenMobile: PropTypes.bool,
    })
  ),
  blue: PropTypes.bool,
  useCarat: PropTypes.bool,
  useComma: PropTypes.bool,
  inline: PropTypes.bool,
};
