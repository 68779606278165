import { useRef, useState } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import cn from "classnames";

import { LinkFill, MoreFill, CloseX, CheckmarkFill } from "@icons";

import { copyTextToClipboard } from "@utils/copyToClipboard";
import { LP_URL } from "@constants/lpUrl";

import { engine } from "./engine";

const { networks } = engine;
const networkList = Object.keys(networks);

const boxclasses =
  "border-box w-12 h-12 flex items-center justify-center border-r lg:border-b border-black-200 bg-white";

export function Share({
  className = "",
  horizontal = false,
  showOnlyThese = ["default"],
  hideShowMore = false,
  content,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [showCopiedTip, setShowCopiedTip] = useState(false);
  const router = useRouter();
  const lpLink = `${LP_URL}${router.asPath}`;
  const copyboxRef = useRef(null);

  const openShareWindow = network => {
    const { options, windowSize } = engine.getWindowSettings(network);
    const url = engine.getUrl(network, content);
    window.open(url, "share", `${options},${windowSize}`);
  };

  const copySuccess = () => {
    setShowCopiedTip(true);
    setTimeout(() => {
      setShowCopiedTip(false);
    }, 2000);
  };

  const copyURIToClipboard = () => {
    copyTextToClipboard(lpLink, copySuccess);
    const { current } = copyboxRef;
    current.value = window.location.href;
    current.focus();
    current.select();
  };

  return (
    <div
      data-testid="flexWrapper"
      className={cn(
        "flex items-center justify-center",
        { "lg:flex-col lg:items-start": !horizontal },
        className
      )}
    >
      <div
        className={cn(
          "hidden w-12 lg:block pr-3 lg:pr-0 text-sm font-semibold text-center",
          { "lg:pb-2": !horizontal }
        )}
      >
        Share
      </div>

      <div
        className={cn("border border-black-200 border-r-0 lg:border-b-0 flex", {
          "lg:flex-col lg:w-12": !horizontal,
        })}
      >
        {showOnlyThese[0] === "default"
          ? networkList.map((network, i) => {
              const { icon: Icon } = networks[network];

              return (
                <button
                  key={`${network}-share-btn`}
                  data-taggyid={`${network}-share-btn`}
                  type="button"
                  className={cn({ "lg:hidden": i > 1 && !isOpen }, boxclasses, {
                    "lg:px-2": horizontal,
                  })}
                  onClick={() => openShareWindow(network)}
                  aria-label={`Share to ${network}`}
                >
                  <Icon />
                </button>
              );
            })
          : showOnlyThese.map((network, i) => {
              const { icon: Icon } = networks[network];
              return (
                <button
                  key={`${network}-share-btn`}
                  data-taggyid={`${network}-share-btn`}
                  type="button"
                  className={cn({ "lg:hidden": i > 1 && !isOpen }, boxclasses, {
                    "lg:px-2": horizontal,
                  })}
                  onClick={() => openShareWindow(network)}
                  aria-label={`Share to ${network}`}
                >
                  <Icon />
                </button>
              );
            })}
        <button
          type="button"
          data-taggyid="copy to clipboard"
          className="w-12 h-12 bg-white border-r border-box border-black-200 lg:border-b"
          onClick={() => copyURIToClipboard()}
          aria-label="Copy to clipboard"
        >
          <div className="flex items-center justify-center h-full">
            {showCopiedTip ? <CheckmarkFill primary="#0057D9" /> : <LinkFill />}
          </div>
          <input
            className="w-0 h-0 opacity-0"
            aria-label="Hidden for copy functionality"
            ref={copyboxRef}
          />
        </button>
        {!hideShowMore && (
          <div className="hidden lg:block" data-testid="showMore">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              data-taggyid="open/close"
              className={boxclasses}
              aria-label="More options"
            >
              {isOpen ? <CloseX /> : <MoreFill />}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

Share.propTypes = {
  className: PropTypes.string,
  content: PropTypes.shape({
    url: PropTypes.string.isRequired,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
  }).isRequired,
  showOnlyThese: PropTypes.arrayOf(PropTypes.string),
  horizontal: PropTypes.bool,
  hideShowMore: PropTypes.bool,
};
