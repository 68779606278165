import { useContext } from "react";

import { CohesionContext } from "@cohesion";

export function useMonarch() {
  const { handleMonarchResponse } = useContext(CohesionContext);

  const monarch = (rule, rid, payload, params, callback) => {
    /**
     * Ajv uses JSON Schema to validate, which does not have type support for functions.
     * Check that callback is present and a function manual and allow Ajv to do the rest.
     */
    if (typeof callback !== "function") {
      throw new Error(
        `'Callback of type '${typeof callback}' is not a function`
      );
    }

    const withCallback = (err, response) => {
      handleMonarchResponse({ err, response });
      callback(err, response);
    };

    window.cohesion("monarch:ready", () => {
      window.monarch(rule, rid, payload, params, withCallback);
    });
  };

  return monarch;
}
