import PropTypes from "prop-types";

import { keyify } from "@utils/keyify";
import { tableCopy } from "./sponsorshipTable";

const getCopy = (type, name) => {
  const vars = {
    "Sponsored by": `This story was crafted collaboratively between ${name} and Lonely Planet. Both parties provided research and curated content to produce this story. We disclose when information isn’t ours.`,
    "Supported by": `Funding for this story is provided by ${name}. All editorial views are those of Lonely Planet alone and reflect our policy of editorial independence and impartiality.`,
    "Paid for by": `This story was paid for and controlled by ${name}. It has been produced to reflect Lonely Planet’s high-quality storytelling, but it may not reflect our editorial views.`,
    "Provided by": `This story was provided by ${name}. Lonely Planet had no involvement with the creation of this content, and it may not reflect our editorial views.`,
  };

  return {
    lede: vars[type],
    roles: tableCopy.filter(cat => cat.heading === type)[0].roles,
  };
};

export function SponsorshipDisclaimer({ sponsorship }) {
  const { type, name } = sponsorship;

  const { lede, roles } = getCopy(type, name);

  return (
    <div className="mt-12 border border-sand-300 rounded p-8">
      <h4 className="font-semibold text-lg">
        {sponsorship.type} {sponsorship.name}
      </h4>

      <p className="my-2 lg:my-4">
        As a travel entertainment and inspirational media outlet, we sometimes
        incorporate brand sponsors into our efforts. This activity is clearly
        labeled across our platforms.
      </p>
      <p className="my-2 lg:my-4">{lede}</p>
      <p className="my-2 lg:my-4">
        With sponsored content, both <span>Lonely Planet</span> and our brand
        partners have specific responsibilities:
      </p>
      <ul className="md:flex">
        {roles.map(({ heading, body }) => (
          <li
            className="mt-2 first:border-b first:border-black-200 py-5 md:first:border-b-0 md:w-1/2 md:first:border-r md:first:border-black-200 md:mr-5"
            key={keyify(heading)}
          >
            <h4 className="font-semibold">{heading}</h4>
            <p>{body}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

SponsorshipDisclaimer.propTypes = {
  sponsorship: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};
